export default function (instance) {
  return {
    async getRequestStates() {
      return await instance.get("request/states");
    },

    async getRequest(requestID) {
      return await instance.get("request/" + requestID);
    },

    async getRequests(getRequests) {
      return await instance.get("request/list/" + getRequests);
    },
    async getRequestsByFilter(filter) {
      return await instance.get(
        "request/listbyfilter?filter=" + JSON.stringify(filter)
      );
    },

    async getRequestsStatisticByFilter(filter) {
      return await instance.get("request/statistic/" + filter);
    },

    async getRequestsStatisticByLogistFilter(filter) {
      return await instance.get("request/statistic/logists/" + filter);
    },

    async getRequestsPromoStatisticByFilter(filter) {
      return await instance.get("request/promostatistic/" + filter);
    },

    async getRequestSources() {
      return await instance.get("request/sources");
    },

    async addRequestSources(data) {
      return await instance.post("request/sources/create", data);
    },

    async updateRequest(data) {
      return await instance.post("request/update", data);
    },

    async requestIsOpen(requestID) {
      return await instance.post("request/open/" + requestID);
    },

    async updatePosition(data) {
      return await instance.post("company/position/update", data);
    },

    async getCfg() {
      return await instance.get("order/cfg");
    },

    async getOrder(orderID) {
      return await instance.get("order/" + orderID);
    },

    async getOrders(filter) {
      return await instance.get("order/list/" + filter);
    },

    async getCartypes_request() {
      return await instance.get("request/cartypes");
    },

    async getOrderStatuses() {
      return await instance.get("order/statuses");
    },

    async updateOrder(data) {
      return await instance.post("order/update", data);
    },

    async updateOrderLite(data) {
      return await instance.post("order/updatelite", data);
    },

    async getRouteTypes() {
      return await instance.get("order/route/types");
    },

    async createRouteNode(orderID, data) {
      return await instance.post(`order/${orderID}/routenode/create`, data);
    },

    async updateRoute(orderID, data) {
      return await instance.post(`order/${orderID}/routenode/update`, data);
    },

    async getRoute(orderID) {
      return await instance.get(`order/${orderID}/route`);
    },

    async getRouteForCargo(orderID) {
      return await instance.get(`order/${orderID}/route/forcargo`);
    },

    async getRouteForContractors(orderID) {
      return await instance.get(`order/${orderID}/route/forcontractors`);
    },

    async getRouteForTrucks(orderID) {
      return await instance.get(`order/${orderID}/route/fortrucks`);
    },

    async updateRouteForTrucks(orderID, data) {
      console.log("apiorder", data);
      return await instance.post(`order/${orderID}/route/fortrucks`, data);
    },

    async getCargoTypes() {
      return await instance.get("order/route/cargotypes");
    },

    async getCargoCategories() {
      return await instance.get("order/route/categories");
    },

    async createInvoice(data) {
      return await instance.post("order/invoice/create", data);
    },

    async getInvoices(id) {
      return await instance.get("order/" + id + "/invoices");
    },
  };
}
