import { createRouter, createWebHistory } from "vue-router";
import store from "../store";
import TestView from "../views/TestView.vue";
import HomeView from "../views/HomeView.vue";
import DashboardView from "../views/DashboardView.vue";
import QuitView from "../views/QuitView.vue";

const routes = [
  {
    path: "/test",
    name: "test",
    meta: { layout: "main", auth: false },
    component: TestView,
  },
  {
    path: "/testinvoices",
    name: "testinvoices",
    meta: { layout: "main", auth: false },
    component: () => import("../views/test/TestInvoices.vue"),
  },
  {
    path: "/testupd",
    name: "testupd",
    meta: { layout: "main", auth: false },
    component: () => import("../views/test/TestUPD.vue"),
  },
  {
    path: "/testorders",
    name: "testorders",
    meta: { layout: "main", auth: false },
    component: () => import("../views/test/TestOrders.vue"),
  },
  {
    path: "/testacts",
    name: "testacts",
    meta: { layout: "main", auth: false },
    component: () => import("../views/test/TestActs.vue"),
  },
  {
    path: "/testsf",
    name: "testsf",
    meta: { layout: "main", auth: false },
    component: () => import("../views/test/TestSF.vue"),
  },
  {
    path: "/testcontracts",
    name: "testcontracts",
    meta: { layout: "main", auth: false },
    component: () => import("../views/test/TestContracts.vue"),
  },
  {
    path: "/testtransportorders",
    name: "testtransportorders",
    meta: { layout: "main", auth: false },
    component: () => import("../views/test/TestTransportOrders.vue"),
  },
  {
    path: "/",
    name: "home",
    meta: { layout: "empty" },
    component: HomeView,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: { layout: "main", auth: true },
    component: DashboardView,
  },
  {
    path: "/signout",
    name: "QuitView",
    meta: { layout: "empty" },
    component: QuitView,
  },
  {
    path: "/notifications",
    name: "notifications",
    meta: { layout: "main", auth: true },
    component: () => import("../views/notifications/NotificationsView.vue"),
  },
  {
    path: "/notification/:id",
    name: "notification",
    meta: { layout: "main", auth: true },
    component: () => import("../views/notifications/NotificationPropView.vue"),
  },

  {
    path: "/accounts",
    name: "accounts",
    meta: { layout: "main", auth: true },
    component: () => import("../views/users/AccountsView.vue"),
  },
  {
    path: "/account/:id",
    name: "accountProp",
    meta: { layout: "main", auth: true },
    component: () => import("../views/users/AccountPropsView.vue"),
  },
  {
    path: "/logistquenue",
    name: "logistquenue",
    meta: { layout: "main", auth: true },
    component: () => import("../views/users/LogistQuenueView.vue"),
  },

  {
    path: "/requests",
    name: "requests",
    meta: { layout: "main", auth: true },
    component: () => import("../views/orders/RequestsView.vue"),
  },
  {
    path: "/requests/closed",
    name: "requests_closed",
    meta: { layout: "main", auth: true },
    component: () => import("../views/orders/RequestsClosedView.vue"),
  },
  {
    path: "/requests/reports",
    name: "requests_reports",
    meta: { layout: "main", auth: true },
    component: () => import("../views/orders/RequestReportsView.vue"),
  },

  {
    path: "/requests/search",
    name: "requests_search",
    meta: { layout: "main", auth: true },
    component: () => import("../views/orders/RequestSearchView.vue"),
  },

  {
    path: "/request/:id",
    name: "requestProp",
    meta: { layout: "main", auth: true },
    component: () => import("../views/orders/RequestPropsView.vue"),
  },

  {
    path: "/admin_event",
    name: "admin_event",
    meta: { layout: "main", auth: true },
    component: () => import("../views/admin/AdminEventView.vue"),
  },

  {
    path: "/company/structure",
    name: "company_structure",
    meta: { layout: "main", auth: true },
    component: () => import("../views/company/CompanyStructure.vue"),
  },
  {
    path: "/compcard/:id",
    name: "CompCard",
    meta: { layout: "main", auth: true },
    component: () => import("../views/company/CompCardView.vue"),
  },
  {
    path: "/company/staff",
    name: "company_staff",
    meta: { layout: "main", auth: true },
    component: () => import("../views/company/CompanyStaffView.vue"),
  },

  {
    path: "/finance/company",
    name: "finance_company",
    meta: { layout: "main", auth: true },
    component: () => import("../views/company/FinanceOurCompany.vue"),
  },

  {
    path: "/company/trucks",
    name: "company_trucks",
    meta: { layout: "main", auth: true },
    component: () => import("../views/company/CompanyTrucksView.vue"),
  },
  {
    path: "/company/drivers",
    name: "company_drivers",
    meta: { layout: "main", auth: true },
    component: () => import("../views/company/CompanyDriversView.vue"),
  },
  {
    path: "/company/storage",
    name: "company_storage",
    meta: { layout: "main", auth: true },
    component: () => import("../views/company/CompanyStorageView.vue"),
  },

  {
    path: "/neworder",
    name: "order_new",
    meta: { layout: "main", auth: true },
    component: () => import("../views/orders/OrderNewView.vue"),
  },

  {
    path: "/neworderlite",
    name: "order_new_lite",
    meta: { layout: "main", auth: true },
    component: () => import("../views/orders/OrderNewLiteView.vue"),
  },

  {
    path: "/orders",
    name: "orders",
    meta: { layout: "main", auth: true },
    component: () => import("../views/orders/OrderListView.vue"),
  },
  {
    path: "/order/:id/",
    name: "order",
    meta: { layout: "main", auth: true },
    component: () => import("../views/orders/OrderView.vue"),
  },
  {
    path: "/order/:id/report/customer",
    name: "order-customer-report",
    meta: { layout: "empty", auth: true },
    component: () => import("../views/orders/reports/OrderReportCustomer.vue"),
  },
  {
    path: "/order/:id/report/executor",
    name: "order-executor-report",
    meta: { layout: "empty", auth: true },
    component: () => import("../views/orders/reports/OrderReportExecutor.vue"),
  },
  {
    path: "/order/:id/report/drivertask/:task_id",
    name: "order-drivertask-report",
    meta: { layout: "empty", auth: true },
    component: () =>
      import("../views/orders/reports/OrderReportDrivertask.vue"),
  },
  {
    path: "/order/drivers",
    name: "order-drivers",
    meta: { layout: "main", auth: true },
    component: () => import("../views/orders/DriversView.vue"),
  },

  {
    path: "/customers",
    name: "customers",
    meta: { layout: "main", auth: true },
    component: () => import("../views/orders/CustomersView.vue"),
  },

  {
    path: "/contractors",
    name: "contractors",
    meta: { layout: "main", auth: true },
    component: () => import("../views/orders/ContractorsView.vue"),
  },

  {
    path: "/services/additional",
    name: "services_additional",
    meta: { layout: "main", auth: true },
    component: () => import("../views/services/AdditionalServicesView.vue"),
  },
  {
    path: "/cargo",
    name: "cargo",
    meta: { layout: "main", auth: true },
    component: () => import("../views/cargo/CargoView.vue"),
  },
  {
    path: "/trucks/types",
    name: "trucks_types",
    meta: { layout: "main", auth: true },
    component: () => import("../views/transport/TrucksTypesView.vue"),
  },
  {
    path: "/truck/:id",
    name: "truck",
    meta: { layout: "main", auth: true },
    component: () => import("../views/transport/TruckView.vue"),
  },
  {
    path: "/dictionaries",
    name: "dictionaries",
    meta: { layout: "main", auth: true },
    component: () => import("../views/misc/DictionariesView.vue"),
  },
  {
    path: "/units",
    name: "units",
    meta: { layout: "main", auth: true },
    component: () => import("../views/misc/UnitsView.vue"),
  },

  {
    path: "/payment_shemes",
    name: "payment_shemes",
    meta: { layout: "main", auth: true },
    component: () => import("../views/contracts/PaymentShemes.vue"),
  },
  {
    path: "/formtypes",
    name: "formtypes",
    meta: { layout: "main", auth: true },
    component: () => import("../views/company/CompanyFormTypesView.vue"),
  },
  {
    path: "/1C",
    name: "1C",
    meta: { layout: "main", auth: true },
    component: () => import("../views/props/Connect1cView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.auth) {
    if (!store.getters["account/isAuthenticated"]) {
      next({ name: "home" });
      return;
    }
  }
  next();
});

export default router;
